<template>
  <b-container class="m-0 p-0">
    <b-row class="mb-2" v-for="(dose, index) in doses" :key="index">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ dose.display }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <NumericInput
                inputRef="input"
                :value="currentEntry[dose.key] || currentEditedEntry[dose.key]"
                @input="updateDose(dose.name, $event)"
                :numberOfDigits="1"
                class="form-control-fx mr-2"
                :required="true"
            />
        <b-select
          size="sm"
          :options="inputFormByPtype.doseUnits"
          text-field="unit"
          value-field="id"
          :value="currentEditedEntry.doseUnit || currentEntry.doseUnit || getSelectedDoseUnit"
          @input="updateUnit"
          required
        ></b-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

import { mapGetters } from "vuex";

import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      doses: [
        {
          name: "min",
          display: "Dose min",
          key: "minDoseValue",
        },
        {
          name: "max",
          display: "Dose max",
          key: "maxDoseValue",
        },
      ],
      doseUnit: null,
    };
  },
  async mounted() {
    await this.$store.dispatch("getNitrogenDoseUnits", {forDose: true, isReference: true});
    if (this.$route.name.includes("edit") && !this.currentEntry.doseUnit && this.inputFormByPtype.doseUnits && this.inputFormByPtype.doseUnits.length) {
      this.updateUnit(this.inputFormByPtype.doseUnits[0].id);
    }
  },
  methods: {
    updateDose(name, value) {
      if (!value) 
        return
      const payload = {};
      const dose = this.doses.find(d => d.name == name);
      payload[dose.key] = parseFloat(value);
      this.$store.dispatch(this.storeEditAction, payload);
    },
    updateUnit(value) {
      this.$store.dispatch(this.storeEditAction, {
        doseUnit: value,
      });
    },
  },
  computed: {
    ...mapGetters(["inputFormByPtype"]),

    /**
     * Retourne l'unité de dose présélectionnée
     */
    getSelectedDoseUnit() {
      if(this.inputFormByPtype.doseUnits && this.inputFormByPtype.doseUnits.length)
        this.updateUnit(this.inputFormByPtype.doseUnits[0].id);
        return this.inputFormByPtype.doseUnits[0].id;
    }
  },
  components: {
    NumericInput
  }
};
</script>

<style lang="scss" scoped></style>
